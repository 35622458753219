import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { padding, colors, mq, typography, zIndex } from "../../lib/presets"
import styled from "@emotion/styled"
import { withLocator } from "../../providers/locator"
import { isBrowser } from "../../lib/is-browser"
import Restaurant from "./restaurant"
import Sticky from 'react-stickynode';


const BackContainer = styled.a({
  display: `block`,
  width: `calc(100%)`,
  lineHeight: `2.5em`,
  padding: `0 ${padding.xSmall}`,
  border: `none`,
  boxShadow: `0 0 1.25em 0 rgba(0,0,0,0.07)`,
  backgroundColor: `#FFFFFF`,
  fontFamily: `titular, sans-serif`,
  letterSpacing: `1.25px`,
  fontStyle: `normal`,
  fontWeight: 700,
  fontSize: `1.375em`,
  color: colors.primary.blue,
  boxSizing: `border-box`,
  cursor: `pointer`,
  [mq.desktop]: {
    width: `calc(100% - ${padding.xSmall} - ${padding.xSmall})`,
    paddingTop: 0,
    minWidth: `25rem`,
    maxWidth: `25rem`,
  },
  ['i']: {
    fontSize: `0.675em`,
    margin: `0 5px 0 0`,
    color: colors.turquesa.three
  }
})

const RestaurantSelected = ({ locator }) => {

  const { selected } = locator

  useEffect(() => {
    if (isBrowser) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
    <Sticky top={105}>
      <BackContainer tabIndex="0" onClick={() => { locator.deselectProperty() }} onKeyPress={() => { locator.deselectProperty() }}><i className="fas fa-arrow-left"></i>BACK</BackContainer>
    </Sticky>
    <Restaurant locator={locator} restaurant={selected} latlng={locator.latlng} isFirst={true} isSelectable={false} hideProductsLink={true} showProducts={true} />
    </>
  )
}

export default withLocator(RestaurantSelected)
