import React from "react"
import PropTypes from "prop-types"
import { padding, colors, mq, typography, zIndex } from "../../lib/presets"
import { distance } from "../../lib/calculate-miles"
import styled from "@emotion/styled"
import { jsx, css } from '@emotion/core'
import Product from "./product"
import ProductHeader from "./product-header"
import { getRestaurantPin, getRestaurantPinHover } from "../../lib/mapPin"
import $ from "jquery";
import '../../styles/global.css';

import StarIcon from '../../images/star-icon.png'
import { IconButton } from "../../styles/buttons"
import RestaurantNoPhoto from '../../images/RestaurantNoPhoto.png'


const RestaurantContainer = styled.div(
  {
    borderBottom: `1px solid #EEE`,
    borderLeft: `10px solid transparent`,
    padding: `${padding.small}`,
    paddingBottom: padding.small,
    position: `relative`,
  },
  props => ({
    display: props.isSelectable === true ? `flex` : `block`,
    [`:hover`]: {
      borderLeft: props.isSelectable === true ? `10px solid ${colors.turquesa.three}` : `10px solid transparent`,
      cursor: props.isSelectable === true ? `pointer` : `default`,
      backgroundColor: props.isSelectable === true ? `#F9F9F9` : `transparent`,
    },
    borderTop: props.isFirst === true ? `1px solid #EEE` : `none`
  })
)

const restaurantContainerFirstChildStyle = {
  borderTop: `1px solid #EEE`,
}

const RestaurantHeader = styled.div(
  {
    justifyContent: `space-between`,
  },
  props => ({
      paddingLeft: props.isSelectable === true ? `20px` : `0px`,
  })
)

const RestaurantImage = styled.div({
  width: `100px`,
  [`img`]: {
    width: `100px`
  },
})

const GoogleRatingDiv = styled.div({
  [`p`]: {
    fontFamily: `Queulat`,
    fontStyle: `normal`,
    fontSize: `0.675em`,
    color: colors.text.medium,
    marginTop: `0.2em`,
    fontStyle: `italic`
  },
  [`.starsSelected`]: {
    marginTop: `15px`
  },
  [`.selected`]: {
    fontSize: `0.8em`,
    marginTop: `3px`
  }
})

const ReserveTable = styled.div({
  backgroundColor: `#008080`,
  fontFamily: `Queulat`,
  fontStyle: `normal`,
  color: `white`,
  textAlign: `center`,
  margin: `10px 0`,
  padding: `5px 0`,
  cursor: `pointer`
})

const RestaurantNameDiv = styled.div(
  {
    fontSize: `1.375em`,
    fontFamily: `titular, sans-serif`,
    fontStyle: `normal`,
    textTransform: `uppercase`,
    color: colors.primary.blue,
    margin: 0,
    padding: 0,
    cursor: `pointer`,
    letterSpacing: `1.5px`
  },
  props => ({
    //cursor: props.isSelectable === true ? `pointer` : `auto`
    cursor: `pointer`
  }))

const RestaurantDistance = styled.div({
  fontFamily: typography.body.family,
  fontStyle: `normal`,
  fontSize: `0.875em`,
  color: colors.text.light,
  position: `absolute`,
  right: 0,
  top: `5px`,
  right: `20px`
})

const RestaurantAddress = styled.div({
  fontFamily: `Queulat`,
  fontStyle: `normal`,
  fontSize: `0.875em`,
  marginBottom: `0.5em`,
  marginTop: `0.1em`,
  color: colors.text.medium,
  lineHeight: 1.4,
  textTransform: `capitalize`,
},
props => ({
  cursor: `pointer`,
  [`span:hover`]: {
    borderBottom: props.isSelectable === false ? `1.0px solid #00A3AD` : `none`
  }
}))

const LinksContainer = styled.div({
  [`a`]: {
    marginRight: padding.default
  }
})

const FaArrow = styled.span({
  float: `right`,
  fontSize: `20px`,
  color: colors.text.light
})

const WorkingHoursTitle = styled.h2({
  fontFamily: `titular, sans-serif`,
  letterSpacing: `1.5px`,
  fontStyle: `normal`,
  fontSize: `1.375em`,
  textTransform: `uppercase`,
  marginBottom: `0`,
  marginTop: `1.0em`,
  lineHeight: 1.4,
})

const WorkingHoursDiv = styled.div({
  fontFamily: `Queulat`,
  fontStyle: `normal`,
  fontSize: `0.8em`,
  marginBottom: `0.5em`,
  marginTop: `0.1em`,
  color: colors.text.medium,
  lineHeight: 1.4,
})

const FeaturedProductsTitle = styled.h2({
  fontFamily: `titular, sans-serif`,
  letterSpacing: `1.5px`,
  fontStyle: `normal`,
  fontSize: `1.375em`,
  textTransform: `uppercase`,
  marginBottom: `0`,
  marginTop: `1.0em`,
  lineHeight: 1.4,
})


const Restaurant = ({ objectId, locator, restaurant, latlng, isFirst = false, isSelectable = true, hideProductsLink, showProducts }) => {

  let { address, products, gmapsData} = restaurant
  let workingHours = null;
  let googleRating = 0;
  try {
    workingHours = gmapsData.workingHours[0].split(/:(.+)/)[1]
  } catch(e) {}
  try {
    googleRating = Math.round(gmapsData.rating)
  } catch(e) {}

  const sortedByCategory = locator.allProducts.all.sort((a, b) => (a.Category > b.Category) ? 1 : -1);

  const productsSorted = []
  sortedByCategory.map(p => {
    products.map(pp => {
      if(p.ID == pp.name) {
        p.price = pp.price;
        p.base_price = pp.base_price;
        productsSorted.push(p)
      }
    })
  })

  const { lineOne, lineTwo, city, state, zipCode } = address || { lineOne: null, lineTwo: null, city: null, state: null, zipCode: null }

  const onRestaurantContainerClick = () => {
    if (locator.selected) {
      return;
    } else {
      return onRestaurantInfoClick();
    }
  }

  const raiting = (r, selected) => {
    let width = `14px`;
    if (selected) width = `20px`;
    const stars = [];
    for (let i = 0; i < r; i++) {
      stars.push(<img key={i.toString()}  src={StarIcon} style={{width: width, marginRight: `2px`}} />)
    }
    return stars;
  }

  const onRestaurantInfoClick = () => {
    if (locator.selected) {
      const location = `${restaurant.name} ${lineOne} ${city} ${state}`;
      const message = JSON.stringify({
        'event': 'ClickedOnRestaurantAddress',
        'eventLabel': location
      });
      window.parent.postMessage(message, '*');
      window.open(`https://www.google.com/maps/search/?api=1&query=${location}`, '_blank');
    } else {
      locator.selectProperty(restaurant)
      $('.PropertyListContainer').scrollTop(0)
      const message = JSON.stringify({
        'event': 'ClickOnRestaurant',
        'eventLabel': restaurant.name
      });
      window.parent.postMessage(message, '*');
    }
  }

  const PhoneNumberHref = (phoneNumber) => {
    return phoneNumber ? 'tel:' + phoneNumber : null
  }

  const RestaurantImageUrl = () => {
    if (restaurant.source === 'spreadsheet' && restaurant.gmapsData.photo) {
      return <img src={'https://storage.googleapis.com/restaurants_locator_photos/' + restaurant.gmapsData.photo} />
    } else {
      return <img src={RestaurantNoPhoto} />
    }
  }

  const WorkingHours = () => {
    if (restaurant.source === 'spreadsheet') {
      if (restaurant.gmapsData && restaurant.gmapsData.workingHours) {
        return <>
        <WorkingHoursTitle>Working Hours</WorkingHoursTitle>
        <WorkingHoursDiv>{gmapsData.workingHours && gmapsData.workingHours.map((wh, index) => {
          return <p key={index}>{wh}</p>
        })}</WorkingHoursDiv>
      </>
      } else {
        return null;
      }
    } else {
      const workingHoursArray = restaurant.workingHours.split('\n')
      return <>
        <WorkingHoursTitle>Working Hours</WorkingHoursTitle>
        <WorkingHoursDiv>{workingHoursArray.map((wh, index) => {
          return <p key={index}>{wh}</p>
        })}</WorkingHoursDiv>
      </>
    }
  }

  const PhoneNumber = () => {
    if (restaurant.source === 'spreadsheet') {
      return <>{gmapsData.phoneNumber &&
        <IconButton onClick={() => onPhoneNumberClick(gmapsData.phoneNumber)} href={PhoneNumberHref(gmapsData.phoneNumber)} isSelectable={isSelectable}><i className="far fa-phone"></i>
          <span>{gmapsData.phoneNumber}</span>
         </IconButton>
      }</>
    } else {
      return <>{restaurant.phoneNumber &&
        <IconButton onClick={() => onPhoneNumberClick(restaurant.phoneNumber)} href={PhoneNumberHref(restaurant.phoneNumber)} isSelectable={isSelectable}><i className="far fa-phone"></i>
          <span>{restaurant.phoneNumber}</span>
         </IconButton>
      }</>
    }
  }

  const onPhoneNumberClick = (phoneNumber) => {
    const message = JSON.stringify({
      'event': 'ClickedOnRestaurantPhoneNumber',
      'eventLabel': phoneNumber
    });
    window.parent.postMessage(message, '*');
  }

  const GoogleRating = () => {
    return <>{ restaurant.source === 'spreadsheet' && googleRating !== 0 &&
      <GoogleRatingDiv>
        <p className={locator.selected ? 'starsSelected' : ''}>{raiting(googleRating, locator.selected)}</p>
        <p className={locator.selected ? 'selected' : ''}>Google reviews</p>
      </GoogleRatingDiv>
    }</>
  }

  const RestaurantName = () => {
    return <RestaurantNameDiv tabIndex="0" onClick={() => { onRestaurantInfoClick() }} onKeyPress={() => { onRestaurantInfoClick() }} isSelectable={isSelectable}>
      {(restaurant.banner === 'Starbucks') ? `Starbucks - ${restaurant.name}` : restaurant.name}
    </RestaurantNameDiv>
  }

  const selected = () => {
    return <>
      <RestaurantContainer
        onClick={() => {onRestaurantContainerClick()}}
        onMouseEnter={ () => $('.pin_' + objectId).attr("src", getRestaurantPinHover(restaurant.name)).css({'z-index': zIndex.mapPinHover}) }
        onMouseLeave={ () =>$('.pin_' + objectId).attr("src", getRestaurantPin(restaurant.name)).css({'z-index': 1}) }
        className={`property_${objectId}`}
        isFirst={isFirst}
        selected={locator.selected}
        isSelectable={isSelectable}
       >
        <RestaurantHeader isSelectable={isSelectable}>
          <RestaurantName />
          <GoogleRating />
          <RestaurantDistance>
            {distance(latlng.lat, latlng.lng, restaurant._geoloc.lat, restaurant._geoloc.lon, "M")} mi
          </RestaurantDistance>
          <ReserveTable tabIndex="0" onClick={() => { onRestaurantInfoClick() }} onKeyPress={() => { onRestaurantInfoClick() }}>Reserve A Table</ReserveTable>
          <RestaurantAddress tabIndex="0" onClick={() => { onRestaurantInfoClick() }} onKeyPress={() => { onRestaurantInfoClick() }} isSelectable={isSelectable}>
            <span>
              {(lineOne != null) && (<>{lineOne.toLowerCase()}</>)}
              {(city != null || state != null || zipCode != null) && (<><br />{city.toLowerCase()}, {state} {zipCode}</>)}
            </span>
          </RestaurantAddress>
          <PhoneNumber />
          <WorkingHours />
          <FeaturedProductsTitle>FEATURED PRODUCTS:</FeaturedProductsTitle>
          {showProducts && (productsSorted.map((product, index) => {
            return <Product locatorType={locator.locatorType} product={product} property={restaurant} key={index} />
          }))}
        </RestaurantHeader>
      </RestaurantContainer>
    </>
  }

  const notSelected = () => {
    return <RestaurantContainer
      onClick={() => {onRestaurantContainerClick()}}
      onMouseEnter={ () => $('.pin_' + objectId).attr("src", getRestaurantPinHover(restaurant.name)).css({'z-index': zIndex.mapPinHover}) }
      onMouseLeave={ () =>$('.pin_' + objectId).attr("src", getRestaurantPin(restaurant.name)).css({'z-index': 1}) }
      className={`property_${objectId}`}
      isFirst={isFirst}
      selected={locator.selected}
      isSelectable={isSelectable}
      >
      <RestaurantImage>
        <RestaurantImageUrl />
        <GoogleRating />
      </RestaurantImage>
      <RestaurantHeader isSelectable={isSelectable}>
        <RestaurantName />
        <RestaurantDistance>
          {distance(latlng.lat, latlng.lng, restaurant._geoloc.lat, restaurant._geoloc.lon, "M")} mi
        </RestaurantDistance>
        <RestaurantAddress tabIndex="0" onClick={() => { onRestaurantInfoClick() }} onKeyPress={() => { onRestaurantInfoClick() }} isSelectable={isSelectable}>
        <span>
          {(lineOne != null) && (<>{lineOne.toLowerCase()}</>)}
          {(city != null || state != null || zipCode != null) && (<><br />{city.toLowerCase()}, {state} {zipCode}</>)}
        </span>
        <p style={{margin: `2px 0`, textTransform: `lowercase`}}>{workingHours}</p>
        <p style={{margin: `2px 0`}}>
          {productsSorted.length > 1 && (`${productsSorted.length} Siete Products Featured`)}
          {productsSorted.length === 1 && (`${productsSorted.length} Siete Product Featured`)}
        </p>
        <p style={{marginTop: `5px`}}>
        {restaurant.dietary.map((dietary, index) => {
          var dietaryImage = locator.allProducts.dietary.find(obj =>  obj.ID === dietary.name)
          return <img key={index.toString()} src={dietaryImage.Image_Url} style={{width: `35px`, marginRight: `5px`}} />
        })}
        </p>
        </RestaurantAddress>
      </RestaurantHeader>
    </RestaurantContainer>
  }

  if (locator.selected) {
    return selected()
  } else {
    return notSelected()
  }
}

export default Restaurant
