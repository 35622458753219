import React, { useContext, useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { padding, colors, mq, zIndex, typography } from "../../lib/presets"
import { isBrowser } from "../../lib/is-browser"
import ResultsBar from "./results-bar"
import RestaurantSelected from "./restaurant-selected"
import Gurantee from "./guarantee"
import Restaurant from "./restaurant"
import { P } from "../../styles/p"
import Button from "../../styles/buttons"
import { withLocator } from "../../providers/locator"
import Fade from 'react-reveal/Fade';
import $ from "jquery";


const RestaurantListContainer = styled(`div`)({
  position: `relative`,
  overflow: `hidden`,
  backgroundColor: `#F5F5F5`,
  [mq.desktop]: {
    overflowY: `scroll`,
    marginTop: 0,
    paddingTop: 0,
    minWidth: `25em`,
    maxWidth: `25em`,
    height: `calc(100vh - 105px)`,
  }
})

const ResultsBarContainer = styled(`div`)({
  width: `100%`,
  padding: `5px ${padding.xSmall} 0 ${padding.xSmall}`,
  boxSizing: `border-box`,
  backgroundColor: colors.background.gray

})

const LoadingContainer = styled(`div`)({
  height: `calc(100vh - 400px)`,
  width: `100%`,
  textAlign: `center`,
  padding: `${padding.default} 0`,
  fontFamily: typography.body.family,
  fontStyle: `normal`,
  fontSize: `0.875em`,
  color: colors.text.light,
  fontWeight: 300,
  lineHeight: 1.4,
  boxSizing: `border-box`,
  padding: ` 0 ${padding.default}`,
  margin: ` ${padding.default} 0`,
  [`i`]: {
    fontSize: `1.4rem`,
    color: colors.text.light,
    marginBottom: `15px`
  },
  [mq.desktop]: {
    minWidth: `25em`,
    maxWidth: `25em`,
  }
})

const NoResults = () => {
  return (
    <>
      <ResultsBarContainer><ResultsBar count={0} /></ResultsBarContainer>
      <P style={{ padding: padding.small, paddingBottom: padding.xxSmall, textAlign: `center` }}>But, we do ship to your front door!</P>
      <P style={{textAlign: `center`}}><Button big href="https://sietefoods.com/collections/" target="_blank" rel="noopener noreferrer">Shop Now</Button></P>
    </>
  )
}

const Results = ({ locator, latlng }) => {
  useEffect(() => {
    if (isBrowser) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div>
      <ResultsBarContainer><ResultsBar count={locator.properties.length} /></ResultsBarContainer>
      {locator.properties.map((restaurant, index) => {
        return <Restaurant objectId={restaurant.objectID} locator={locator} restaurant={restaurant} latlng={locator.latlng} isFirst={index === 0} key={index} />
      })}
    </div>
  )
}

const RestaurantList = ({ locator }) => {
  const RestaurantListContent = () => {
    return (
    <>
      {(locator.locationDenied === true && locator.properties.length === 0) && (<LoadingContainer>Location services are turned off.<br />Begin by searching above.</LoadingContainer>)}
      {locator.isLoading == true && (<LoadingContainer><i className="far fa-spinner-third fa-spin"></i><div>Getting current location…</div></LoadingContainer>)}
      {locator.selected && <RestaurantSelected />}
      {(locator.properties.length > 0 && locator.selected == null) && <Results locator={locator} />}
      {(locator.properties.length === 0 && locator.latlng != null) && <NoResults />}
    </>
    )
  }

  return (
    <RestaurantListContainer className={'PropertyListContainer'}>
      {locator.hidePropertiesList === false ? <RestaurantListContent /> : null}
    </RestaurantListContainer>
  )
}

export default withLocator(RestaurantList)
