import React, { Component, useState } from "react"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import NavigationBar from "../components/locator/navigation-bar"
import RestaurantsList from "../components/locator/restaurants-list"
import { MapWithLocator } from "../components/locator/map"
import SEO from "../components/seo"
import Helmet from "react-helmet"
import { zIndex, mq, colors, padding } from "../lib/presets"
import Fade from 'react-reveal/Fade';
import { withLocator } from "../providers/locator"
import SearchByProduct from "../components/locator/search-by-product"
import products from '../lib/products'


const Loader = styled(`div`)({
  position: `absolute`,
  zIndex: zIndex.loader,
  height: `100%`,
  width: `100%`,
  background: `#F5F5F5`
})

const RestaurantLocatorContainer = styled(`div`)({
  display: `flex`,
  width: `100%`,
  height: `100vh`,
  flexDirection: `column`,
  [mq.desktop]: {
    overflow: `hidden`
  }
})

const MapRestaurantListContainer = styled(`div`)(
  {
    display: `flex`,
    flexGrow: 1,
    flexDirection: `column-reverse`,
    [mq.desktop]: {
      flexDirection: `row`,
    },
  }
)

class RestaurantLocator extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fontsLoaded: false
    }
    props.locator.setLocatorType('restaurants')
  }

  componentDidMount() {
    const _this = this;

    if (!document.fonts) {
      _this.setState({fontsLoaded: true})
      return;
    }
    document.fonts.ready.then(() => {
      setTimeout(() => {
        _this.setState({fontsLoaded: true})
      }, 1000);
    })

  }

  //const [showOnScroll, setShowOnScroll] = useState(false)

  render() {
    const {locator} = this.props
    const gmapsSrc = "https://maps.googleapis.com/maps/api/js?key=" + process.env.GATSBY_GOOGLE_MAPS_API_KEY + "&libraries=places&id=google-map-script"
    return (<>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <script src="https://kit.fontawesome.com/413198f958.js"></script>
        <link href="https://fonts.googleapis.com/css?family=Arvo:400,700&display=swap" rel="stylesheet" />
        <link rel="stylesheet" href="https://use.typekit.net/xnb5eqk.css" />
        <script
          id="google-map-script"
          type="text/javascript"
          src={gmapsSrc}
        />

      </Helmet>
      <SEO />
      {this.state.fontsLoaded == false &&
      <Loader>
        <div className={'lds-dual-ring'}></div>
      </Loader>}
      <RestaurantLocatorContainer>
        <NavigationBar locator={locator} />
        <SearchByProduct id={'sbp'} locator={locator} products={products} />
        <MapRestaurantListContainer>
          <RestaurantsList />
          <MapWithLocator />
        </MapRestaurantListContainer>
      </RestaurantLocatorContainer>
    </>)
  }
}

export default withLocator(RestaurantLocator)
